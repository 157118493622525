<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3 User Management&nbsp;
							el-popover(
								placement="top-start"
								width="480"
								title="User Types"
								trigger="hover"
								)
								el-divider
								label.d-block
									p
										b ADMIN:&nbsp;
										span Highest Privileges - Role cannot be changed, can commit CRUD operations on users, can see leads and delete leads, can create, update, <sup*</sup>delete and view Integrations. Can view dashboard.
								label.d-block
									p
										b MODERATOR:&nbsp;
										span Can commit CRUD operations on users, can see leads and delete leads, can create, update, and view Integrations. Can view dashboard.
								label.d-block
									p
										b MANAGER:&nbsp;
										span Can view dashboard and leads, download a spreadsheet version.
									//Affiliate can view only it's list ()
								label.d-block
									p
										b AFFILIATE:&nbsp;
										span Can view dashboard and leads with restrictions*.
								label.d-block
									p
										b GUEST:&nbsp;
										span Can view dashboard only.

								el-divider
								small.text-danger
									i *CRUD - Create, Read, Update, Delete
								i.el-icon-question.chooseable.no-deco(slot="reference")
						el-button(v-b-tooltip.html.hover.top="", title="Add new user", type="success", icon="el-icon-plus", size="small", @click="$router.push({name:'user-create'})")
			el-row(:gutter="24")
				el-col(:xs="24")
					el-form
						el-row(:gutter="10")
							el-col(:xs="24" :sm="12" :md="6")
								el-form-item(label="By Keyword" label-position="top" label-width="auto")
									el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData" )
							el-col(:xs="24" :sm="12" :md="6")
								el-form-item(label="By Role" label-position="top" label-width="auto")
									el-select.w-100(filterable="", v-model="filters.role" @change="fetchData" )
										el-option(value="" label="- Select Role -" )
										el-option(v-for="o of roles_options" :value="o.v" :label="o.t")
						el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
							el-table-column(align='center', label='#' :width='55')
								template(slot-scope='scope')
									| {{ scope.row.id }}
							el-table-column( align='center', label="Username")
								template(slot-scope="scope")
									el-badge.item-u-mgmt(is-dot="" v-if="Date.now() - scope.row.last_login_raw < 60000" v-b-tooltip.html.hover.right="", title="Online")
										span.chooseable() {{ scope.row.username || '-' }}
									span.chooseable(v-else="", v-b-tooltip.html.hover.right="", title="Offline" ) {{ scope.row.username || '-' }}
							el-table-column( align='center', label="E-Mail")
								template(slot-scope="scope")
									span.chooseable() {{ scope.row.email || '-' }}
							el-table-column( align='center', label="Full Name")
								template(slot-scope="scope")
									span.chooseable() {{ `${scope.row.first_name} ${scope.row.last_name}` || '-' }}
							el-table-column( align='center', label="Last Login")
								template(slot-scope="scope")
									span.chooseable() {{ scope.row.last_login || '-' }}
							el-table-column( align='center', label="Active")
								template(slot-scope="scope")
									font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.in_use", :icon="['fas', 'times']")
									font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")
							el-table-column( align='center', label="Role" :width="130")
								template(slot-scope="scope")
									el-tag(:type="scope.row.role_name | t_status") {{ scope.row.role_name || '-' }}
							el-table-column( align='center', label="Actions" :width="180")
								template(slot-scope="scope")
									.d-flex.justify-content-center
										el-button.small-icon(v-if="scope.row.role_name !== 'ADMIN'" v-b-tooltip.html.hover.left="", title="Edit User", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'user-edit', params: {id: scope.row.id}})")
										el-button.small-icon(v-if="scope.row.role_name !== 'ADMIN' && scope.row.role === '4'" v-b-tooltip.html.hover.top="", title="Resend API Key to user's E-Mail", type="warning", icon="el-icon-refresh", size="small", @click="resendApiKey(scope.row.uuid)")
										el-button.small-icon(v-if="scope.row.role_name !== 'ADMIN' && scope.row.role === '4'" v-b-tooltip.html.hover.top="", title="Reset User's Login Attempts", type="none", icon="el-icon-refresh-left", size="small", @click="resendLoginAttempts(scope.row.uuid)")
										el-button.small-icon(v-if="scope.row.role_name !== 'ADMIN'" v-b-tooltip.html.hover.top="", title="Remove User", type="danger", icon="el-icon-delete", size="small", @click="removeEntity(scope.row)")
						.d-flex.justify-content-between
							b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


							el-row
								el-col(:span="24")
									el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
										el-option(label="10 Per Page", :value="10")
										el-option(label="20 Per Page", :value="20")
										el-option(label="50 Per Page", :value="50")
										el-option(label="100 Per Page", :value="100")
										el-option(label="200 Per Page", :value="200")
									// Table with users


</template>

<style lang="scss" scoped>
.small-icon {
	padding: 9px;
}
</style>

<style lang="scss">
	.item-u-mgmt {
		margin-top: 10px;
		.el-badge__content {
			right: 0 !important;
		}
		/*margin-right: 40px;*/
	}
</style>


<script>
	export default {
		filters: {
			t_status(status) {
				const statusMap = {
					GUEST: 'primary',
					AFFILIATE: 'none',
					MANAGER: 'success',
					MODERATOR: 'warning',
					ADMIN:'danger',
					WORKER:'info',
				};
				return statusMap[status]
			},
			status: ''
		},
		data() {
			return {
				fetchWatcher: null,
				busy: false,
				list: [],
				paginate: {
					keyword: '',
					limit: 10,
					page: 1
				},
				filters: {
					role: '',
				},
				roles_options: []
			}
		},
		async created() {
			await this.fetchData();
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: '4b669104-0ac0-4df7-a5bb-a86fc0aacbb5' })
				.then(options => this.roles_options = options.roles);
		},
		mounted() {
			this.fetchWatcher = setInterval(() => {
				this.fetchData().then();
			}, 30000);
		},
		beforeDestroy() {
			clearInterval(this.fetchWatcher);
			this.fetchWatcher = null;
		},
		methods: {
			resendLoginAttempts(uuid) {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('PUT', 'user/reset-login-attempts', { uuid })
						.then(res => {
							this.$notify.success({title: 'API Response', message: 'The reties counter was successfully reset'})
							loader.hide()
						})
						.catch(e => loader.hide())
			},
			resendApiKey(uuid) {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'user/send-api-key', { uuid })
						.then(res => {
							this.$notify.success({title: 'API Response', message: 'The API key was sent to the affiliate. Ask them to check their E-Mail inbox'})
							loader.hide()
						})
						.catch(e => loader.hide())
			},
			fetchData() {
				// let $data = Object.assign({}, this.paginate, this.filters);
				setTimeout(() => {
					this.$apix.sendHttpRequest('GET', 'user/list', Object.assign({}, this.paginate, this.filters))
							.then(res => {
								this.list = res.rows;
								this.count = res.count;
							})
				}, 50)

			},
			removeEntity(u) {
				this.$confirm(`Are you sure that you want to remove the user ${u.username}? This operation cannot be undone!`)
						.then(async () => {
							this.busy = true;
							this.$apix.sendHttpRequest('DELETE', 'user/' + u.id)
									.then(resp => {
										this.busy = false;
										if(resp.success) this.$notify.success({title: 'API Response', message: 'the Funnel Family entry was deleted successfully'})
										this.fetchData()
									})
									.catch(err => {
										console.error(err);
										this.busy = false;
									})
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
			}
		}
	}
</script>
